<template>
  <div style="clear:both" class="scroll-container">
    <el-table
        :data="log_list"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >

      <el-table-column
          align="center"
          prop="oname"
          sortable='oname'
          label="企业名称"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="library"
          label="库房名称"
          sortable='library'
      >
      </el-table-column>
      <el-table-column
          prop="ename"
          sortable='ename'
          label="设备名称"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="model"
          sortable='model'
          label="设备类型"
          align="center"
      ></el-table-column>
      <el-table-column
          align="center"
          prop="temperature"
          sortable='temperature'
          label="温度"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="humidity"
          sortable='humidity'
          label="湿度"
      >
      </el-table-column>
      <el-table-column
          align="center"
          sortable='time'
          prop="time"
          label="时间"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    log_list: Array,
    sortthiscolumn: Function,
  }
}
</script>

<style scoped>
.scroll-container {
  height: calc(100% - 30px);
  overflow: auto;
}
</style>