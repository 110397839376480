<template>
  <div style="height:100%;">
    <index path="/log/temperature-list"></index>
    <div style="position: absolute;right: 1%;width: 82%;height:100%;">
      <div style="height: 50px;padding: 10px;">
        <div style="float: left;width: 17%;margin-left: 1%;margin-top: 8px;color: #808080;">
          <span>历史温度（{{ logList.length }}条/共{{ count }}条）</span>
        </div>
        <div class="tools-bar" style="float: right;">
          <el-select v-model="enterprise" placeholder="企业" size="mini" style="width:9vw; padding-right: 0.7vw" clearable @change="getOrginList(null)">
            <el-option
                v-for="item in enterpriseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select v-model="equipment" placeholder="设备" size="mini" style="width:9vw; padding-right: 0.7vw" clearable @change="search">
            <el-option
                v-for="item in equipmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-date-picker
              size="mini"
              style="width: 15vw"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime">
          </el-date-picker>
          <el-button style="margin-left: 0.7vw;" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>

        </div>
      </div>
      <list style="height: 82.8vh;" :log_list="logList" :sortthiscolumn="sortthiscolumn">></list>
      <div class="bottom" style="float: right;margin-right: 35px;margin-top: 1vh;">
        <div style="float: left;margin-left: 35px;margin-top: 10px;margin-right: 35px;">
          <el-button size="mini" type="primary" icon="el-icon-download" @click="exportAlertLog" >导出</el-button>
        </div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import index from '../index'
import list from './list'
import { temperatureExport,getTemperatureList,getEquipmentList,getOrgin } from '@/api/log'

export default {
  components: {
    index,
    list
  },
  computed: {
    defaultTime() {
      var time = new Date();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      let defaultTime = []
      defaultTime[0] = '00:00:00'
      defaultTime[1] = hour + ':' + minute + ':' + second
      return defaultTime;
    }
  },
  data() {
    return {
      enterpriseList:[],
      equipmentList:[],
      equipment:null,
      enterprise:null,
      date: [],
      count:0,
      logList:[],
      list:[],
      pageNo:1,
      pageSize:10,
      name: null,
      column:{prop: 'time', order: 'descending'},
    }
  },
  mounted() {
    this.enterprise = this.$route.query.oid ? parseInt(this.$route.query.oid) : null

    getOrgin().then(res => {
      this.enterpriseList = res.data.data
    })
    this.getOrginList(this.$route.query.eid ?parseInt(this.$route.query.eid) : null)

  },
  methods: {
    getOrginList(equipement){
      this.equipment = equipement
      getEquipmentList({enterprise:this.enterprise,type:3}).then(res => {
        this.equipmentList = res.data.data
        this.search()
      })
    },
    //排序方式
    sortthiscolumn(column) {
      this.column = column
      this.search();
    },
    search(){
      this.pageNo = 1
      this.init()
    },
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    init(){
      this.pageSize=this.pageSizeIndex
      var list
      let begin = this.date && this.date.length > 1 && this.date[0] ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let end = this.date && this.date.length > 1 && this.date[1] ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      getTemperatureList({
        timeBegin:begin,
        timeEnd:end,
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        name:this.name,
        enterprise:this.enterprise,
        equipment:this.equipment,
        prop:this.column.prop,
        order:this.column.order,
      }).then(res => {
        list = res.data.data.list
        this.count=res.data.data.totalRow
        this.logList = list
      })
    },
    exportAlertLog(){
      if ((this.date && this.date.length > 1 && this.date[0]) || (this.date && this.date.length > 1 && this.date[1])) {

      }else{
        this.$message.warning("请选择开始时间和结束时间")
        return
      }
      let begin = this.date && this.date.length > 1 && this.date[0] ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let end = this.date && this.date.length > 1 && this.date[1] ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let url = "/api/log/temperatureExport"
      let flag = 0;
      if(begin){
        flag++
        url+="?timeBegin="+begin
      }
      if(end){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="timeEnd="+end
        flag++
      }
      if(this.enterprise){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="enterprise="+this.enterprise
        flag++
      }
      if(this.equipment){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="equipment="+this.equipment
        flag++
      }
      if(this.column.prop){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="prop="+this.column.prop
        flag++
      }
      if(this.column.order){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="order="+this.column.order
        flag++
      }
      window.location.href = url
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: 1%;
  box-shadow: 0px -3px 3px #c4c4c4;
  .el-pagination{
    float: right;
    margin-top: 5px;
    margin-right:5px;
  }
}
</style>